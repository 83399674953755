.page-template-page-components, .program-template-page-components {



	.repeaterblock-wrap.quoterotator {padding:0px; background-color:$blue;}
    
    .repeaterblock-wrap {
        padding: 10px 30px 30px 30px;
        margin-top: 30px;

        .cu-section__news-feed.eventdateblock.quoterotator {padding:0px; background-color:inherit; max-width:90%; margin-left:auto; margin-right:auto;}

        .cu-section__news-feed.postlist.quoterotator .news-feed  {

            .swiper-button-next {
                background-image: url(/public/svg/slider-arrow-next-white.svg);
            }
            .swiper-button-prev {
                background-image: url(/public/svg/slider-arrow-prev-white.svg);
            }

        }
        
        .quoterotator {
            padding: 0px;background-color: inherit;
            .eventinfo {width:100%; background-color:inherit; color:$white; padding: 20px 45px; justify-content:center;}
            .eventinfo::before {content: url(/public/svg/quoterotatorldquo.svg); font-size: 4em; position: absolute; top:-30px; left:0px;}
            .eventinfo::after {content: url(/public/svg/quoterotatorrdquo.svg); font-size: 4em; position: absolute; bottom:0px; right:0px;}
            .eventdaterotator {background-color:$blue;}
            .eventdatebox {border: none; box-shadow:none; -webkit-box-shadow:none; }
            margin-top:6px;



        }

        .postexcerpt {
            color: #222222;
        }
        .date {
            color: $blue;
        }
        .datespan {
            border-bottom: solid 1px $gold;
            color: $blue;
        }
        .postlist.eventdateblock {
            background-color: $white;
            margin-top: 60px;
            margin-bottom: 30px;
            margin-top: 0px;

        }

        .infodesc ul {list-style: square;
        margin: 2em 0 auto;
        padding-left: 25px;

        li::marker {
            color: #e8aa15;
        }

        li {
            margin-bottom:1em;
        }

        }

     



        .inner-wrap {
            padding: 0 0 10px 0px;

            .linewrapper{
                border-bottom: solid 2px $gold; text-align: center; margin-left:auto; margin-right:auto; display: inline-block; padding-bottom: 0px; margin-bottom: 15px; text-align: center;
            }
        }
        
        .eventdateblock {

            .swiper-pagination-bullet-active {
                background-color:$blue;
            }

            .swiper-pagination {
                bottom:0px;
                display:none;
            }

            @media (max-width:599px) {
                .swiper-pagination {
                    display:block;
                }
        
        }

            h3.headline {
                text-transform: none;
                margin-bottom: 10px;
            }

            .cu-card__post {
                height: auto;
            }


            .cu-card__post:hover {
                box-shadow: 6px 6px 24px rgba(0, 0, 0, .16);
                -moz-box-shadow: 6px 6px 24px rgba(0, 0, 0, .16);
                -webkit-box-shadow: 6px 6px 24px rgba(0, 0, 0, .16);
            }
            .swiper-slide {
                display: flex;
                background: none;
            }

            @media (max-width:599px) {
                .swiper-pagination {
                    margin-right:20px;
                }
            }

            .cu-section__simple-content {
                .inner-wrap {
                    padding-bottom: 0px;
                }
            }

            .cu-card__post:hover {
                -webkit-box-shadow: 6px 6px 10px rgba(0, 0, 0, .16);
                box-shadow: 6px 6px 10px rgba(0, 0, 0, .16);
                -moz-box-shadow: 6px 6px 10px rgba(0, 0, 0, .16);
            }

        }

        .cu-section__news-feed.postlist {
            .news-feed {
                .swiper-containereventdate {
                    overflow:hidden;
                    padding: 30px 10px;
                }

                .swiper-button-next {
                    background-color: rgba(0,0,0,0);
                    right: -60px;
                    top: 40%;
                    background-image: url(/public/svg/slider-arrow-next-blue.svg);
                }
                .swiper-button-prev {
                    background-color: rgba(0,0,0,0);
                    left: -60px;
                    top: 40%;
                    background-image: url(/public/svg/slider-arrow-prev-blue.svg);
                }
            }
        }
        .eventdate {
            color: $white;
            background-color: $blue;
            padding: 10px;
            width: 29%;
            text-align: center;
            font-weight: 700;
            font-size: 1.5em;
            align-content: center;
        }
        .eventdatebox {
            display: flex;
            height: 80%;
            border-radius: 15px;
            -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
            -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
            overflow: hidden;
            

            a.wholeboxlink {
            height: 100%;
            left: 0;
            overflow: hidden;
            position: absolute;
            text-indent: -9999px;
            top: 0;
            width: 100%;
            }



        }
        .eventinfo {
            width: 71%;
            background-color: $white;
            padding: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            h3 {
                color: $blue;
                font-family: $sc_font;
                font-weight: 600;
            }
        }
        .cu-section__news-feed {
            .news-feed {
                .filter-list-item {
                    background: none;
                }
                margin-top: 0px;
            }
        }
        .eventtype {
            font-size: .9em;
            color: $blue;
        }
        .cu-section__news-feed.eventdateblock {
            padding: 10px 0px 10px;
            padding: 10px 0px 10px;
            .news-feed {
                .swiper-container {
                    padding: 30px 10px;
                }
            }
            .fxboxcontainer {
                .fxboxitem {
                    a.wholeboxlink {
                        height: 100%;
                        left: 0;
                        overflow: hidden;
                        position: absolute;
                        text-indent: -9999px;
                        top: 0;
                        width: 100%;
                    }
                }
            }
        }
        
        .cu-card__post {
            .info {
                .date {
                    &:before {
                        display: none;
                    }
                }
            }
        }
        .cu-section__news-feed.postlist {
            .news-feed {
                .swiper-button-prev {
                    background-color: rgba(0,0,0,0);
                    right: -60px;
                    top: 40%;
                    background-image: url(/public/svg/slider-arrow-prev-blue.svg);
                }
                .swiper-button-next{
                    background-color: rgba(0,0,0,0);
                    background-image: url(/public/svg/slider-arrow-next-blue.svg);
                }
                
            }
        }
        
        .cu-section__news-feed {
            .news-feed {
                .swiper-container {
                    padding: 10px;
                }
            }   
        }

        .fxboxcontainer {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-content: flex-start;
            gap: 30px 30px;
            width: 100%;
            max-width: 1200px;
            margin-left: auto;
            margin-right: auto;
            .button-wrap {
                margin-top: auto;
                margin-top: auto;
                margin-bottom: 20px;
            }
            a {
                text-decoration: none;
            }
            .fxboxitem.center {
                text-align: center;
            }
            .fxboxitem.gray {
                background-color: $lgray;
            }
            .fxboxitem.white {
                background-color: $white;
            }
            .fxboxitem.blue {
                background-color: $blue;
                color: $white;
                h3 {
                    color: $white;
                }
                h2 {
                    color: $white;
                }
            }
            .fxboxitem.replinkbox.blue {
                &:hover {
                    background-color: $gold;
                    color: $white;
                    picture {
                        opacity: .7;
                    }
                }
            }
            .fxboxitem.blue-alternating {
                h3 {
                    color: $white;
                    color: $white;
                }
                h2 {
                    color: $white;
                }
                background-color: $blue;
                color: $white;
                &:nth-child(even) {
                    background-color: $dblue;
                }
            }
            .fxboxitem {
                position: relative;
                a.wholeboxlink {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    text-indent: -9999px;
                    overflow: hidden;
                }
            }
        }
        .fxboxitem {
            min-height: 15px;
            display: flex;
            flex-direction: column;
            img {
                width: 100%;
                height:auto;
            }
            h3 {
                margin-bottom: 8px;
                margin-top: 8px;
            }
        }
        .x-2up {
            flex: 0 0 calc(50% - (30px * 1) / 2);
        }
        .x-3up {
            flex: 0 0 calc(33.33% - (30px * 2) / 3);
        }
        .x-4up {
            flex: 0 0 calc(25% - (30px * 3) / 4);
        }
        .x-5up {
            flex: 0 0 calc(20% - (30px * 4) / 5);
        }
        .x-6up {
            flex: 0 0 calc(16.66% - (30px * 5) / 6);
        }
        .copy {
            .fxboxitem {
                p {
                    color: $blue;
                    font-size: .875rem;
                    font-weight: 700;
                    line-height: 1em;
                    margin-bottom: 1em;
                }
            }
        }
        .flexboxitem {
            .infodesc {
                padding-bottom: 15px;
            }
        }
        #collier {
            scroll-margin-top: 130px;
        }
        #lyons {
            scroll-margin-top: 130px;
        }
        #olasky {
            scroll-margin-top: 130px;
        }
        #wear {
            scroll-margin-top: 130px;
        }
        #wcform {
            scroll-margin-top: 130px;
        }
        .fxboxbottom {
            margin-top: auto;
            text-align: center;
            padding-bottom: 15px;
        }
        .info {
            padding: 20px 20px 0px 20px;
        }
        .infodesc {
            padding-bottom: 20px;
        }
        .repeaterblock-wrap {
            padding: 10px 30px 30px 30px;
            margin-top: 30px;
        }
        .fxboxcontainer.wholeblocklink.verticalcenter {
            .fxboxitem {
                justify-content: space-around;
            }
        }
        .fxboxcontainer.wholeblocklink {
            .fxboxitem {
                h2.headline {
                    text-transform: none;
                    line-height: 1em;
                    letter-spacing: normal;
                    font-family: $c_font;
                    font-size: 28px;
                }
            }
        }
        .fxboxcontainer.faculty {
            .fxboxitem {
                h2.headline {
                    text-transform: none;
                    line-height: 1em;
                    letter-spacing: normal;
                    font-family: $c_font;
                    font-size: 28px;
                }
            }
        }

        @media (max-width:1212px) {
            .x-6up {
                flex: 0 0 calc(25% - (30px * 3) / 4);
            }
        }


        @media (max-width:1265px) {
            .x-6up {
                flex: 0 0 calc(33.33% - (30px * 3) / 4);
            }
            .x-5up {
                flex: 0 0 calc(33.33% - (30px * 3) / 4);
            }
        }
        @media (max-width:900px) {
            .x-6up {
                flex: 0 0 calc(33.33% - (30px * 2) / 3);
            }
            .x-5up {
                flex: 0 0 calc(33.33% - (30px * 2) / 3);
            }
            .x-4up {
                flex: 0 0 calc(33.33% - (30px * 2) / 3);
            }
        }
        @media (max-width:700px) {
            .x-6up {
                flex: 0 0 calc(50% - (30px * 1) / 2);
            }
            .x-5up {
                flex: 0 0 calc(50% - (30px * 1) / 2);
            }
            .x-4up {
                flex: 0 0 calc(50% - (30px * 1) / 2);
            }
            .x-3up {
                flex: 0 0 calc(50% - (30px * 1) / 2);
            }
        }
        @media (max-width:599px) {
            .fxboxcontainer {
                display: block;
            }
            .fxboxitem {
                .btn {
                    width: inherit !important;
                }
                margin-bottom: 20px;
                margin-left: auto;
                margin-right: auto;
            }
            .fxboxitem.mw100 {
                max-width: 100%;
            }
            .fxboxitem.mw90 {
                max-width: 90%;
            }
            .fxboxitem.mw80 {
                max-width: 80%;
            }
            .fxboxitem.mw70 {
                max-width: 70%;
            }
            .fxboxitem.mw60 {
                max-width: 60%;
            }
            .fxboxitem.mw50 {
                max-width: 50%;
            }
        }	


}

}
