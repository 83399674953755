.page-template-page-components, .program-template-page-components {
	
	.maincontent {

	.cu-section__explore-nav {margin-left:auto; margin-right:auto;}
	.cu-section__explore-nav.cu24subnavlinks {
		background-color:#cc9933; padding-left:10px; padding-right:10px; height:45px;
		 .text-btn {border-bottom: solid 1px #13294b; text-align:left; min-width:200px; padding-top:1px;}
		 .text-btn:hover, .arrow-btn.back:hover{border-bottom: solid 2px #13294b; color:#13294b !important; font-weight:bold;}
		 .text-btn:hover {padding-top:0px;}
	}
	
	.herotext {background-color:rbga(0,0,50,.7); padding: 20px 40px; z-index:2;}

	.heroformholder {width:400px; margin-left:auto; margin-right:auto; background-color:$white; margin-top:40px; box-shadow: 0 0 8px rgba(19, 41, 75, .8);  -moz-box-shadow: 0 0 8px rgba(19, 41, 75, .8); -webkit-box-shadow: 0 0 8px rgba(19, 41, 75, .8);}

	.back-btn {float:left;}
	
	.cb_headerimagetitle {color:$white; background-color:$blue; padding: 15px 30px 20px; display: inline-block; min-width:45%; text-align:center; position:relative; z-index:5;}

	.cb_headerimage {
		display:flex; align-items:center; justify-content:space-around; -webkit-box-align: center;
				-webkit-box-pack: center;
				position: relative;
				//width: calc(100% - 80px);
				z-index: 2;
				margin: 0px auto;
		
		.image {height: 100%; left:0; position:absolute; top:0; width:100%; z-index:0; display:none;}
		.mobile-image {height: 100%; left:0; position:absolute; top:0; width:100%; z-index:0; display:none;}
		.tablet-image {height: 100%; left:0; position:absolute; top:0; width:100%; z-index:0; display:none;}
	
	
	
	}

	#form_page_2de57e0a-b357-4c67-b73a-9ef3c6c73d31 div.form_question label {color:$blue !important; margin-bottom:0px; font-family: acumin-pro-semi-condensed, Helvetica, Arial, sans-serif;}
	#form_2de57e0a-b357-4c67-b73a-9ef3c6c73d31_container div.form_question.form_layout_table .form_label {color:$blue !important; margin-bottom:0px; font-family: acumin-pro-semi-condensed, Helvetica, Arial, sans-serif;}
	#form_page_2de57e0a-b357-4c67-b73a-9ef3c6c73d31 input.multipgbtn {background-color:$gold !important;}
	.rihd{border-bottom: solid 2px $gold; color:$blue; font-family: acumin-pro-condensed, Helvetica, Arial, sans-serif;}
	.rihdhd{margin-left:auto; margin-right:auto; width:100%; max-width:350px; font-size:35px; font-weight:700;}
	.homeherobox {background-color: rgba(19, 41, 75, .8); color:$white; display: inline-block; padding:20px; font-size:3em; font-weight:800;font-family: acumin-pro-semi-condensed, Helvetica, Arial, sans-serif;}
	.homeheroboxholder {padding-bottom:26px;}
	.cb_headerimage.short {height:300px;}
	.cb_headerimage.medium {height:450px;}

	@media only screen and (min-width: 800px) {.herotext {max-width:60%;}}
	

	@media only screen and (max-width: 599px) {
		.cb_headerimagetitle {width:100%;}
		.cb_headerimage .mobile-image {display:flex !important;}

	}

	@media only screen and (min-width:600px) and (max-width: 999px) {
		.cb_headerimage .tablet-image {display:flex !important;}
	}

	@media only screen and (min-width:1000px) { 
		.cb_headerimage .image {display:flex !important;}
	}


	@media (min-width:999px) {.inner-wrap.heroformholder {position:absolute; top:47px; right:50px; padding: 10px 0px 10px; z-index:2;} .cb_headerimage {align-items:flex-end; justify-content:space-between;} 	.inner-wrap.heroformholder {margin-top:0px;} }





	}


}